<template>
  <div class="addCondition">
    <el-dropdown
      :hide-on-click="false"
    >
      <el-button type="primary">
        {{ label }}<i class="el-icon-arrow-down el-icon--right" />
      </el-button>
      <el-dropdown-menu class="maxDropDown" slot="dropdown">
        <el-dropdown-item v-for="(condition, index) in list" :key="index" :disabled="disableItem(condition)">
          <el-checkbox v-model="condition.checked" :disabled="condition.disabled" @change="change(condition)">{{setLabel(condition)}}</el-checkbox>
        </el-dropdown-item>

        <template v-if="showSellectAll">
          <template v-if="isSelectAll">
              <span class="selectAllAndClearAll" @click="clearAllOptions()">取消选择</span>
            </template>
            <template v-else>
              <span class="selectAllAndClearAll" @click="selectAllOptions()">全部选择</span>
            </template>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    
  </div>
</template>

<script>
  import {SET_QUERY_SHOW_KEY_SPLIT,HEAD_FOOT_CODE} from '@constant'

  export default {
    name: 'addCondition',
    props: {
      label: {
        type: String,
        default: '增加查询条件'
      },
      keyWord: {
        type: String,
        default: 'key'
      },
      defaultValue: {
        type: String,
        default: ''
      },
      isShowSuffix: {
        type: Boolean,
        default: false
      },
      conditions: {
        type: Array,
        default: () => []
      },
      showSellectAll: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      this.list = this.conditions.map(item=>item)
      this.isSelectAll = !this.conditions.some(item => item.checked === false)
      this.$nextTick(() => {
        this.setDefaultValueChecked();
      })
    },
    data(){
      return {
        list:[],
        isSelectAll: false
      }
    },
    watch: {
      conditions(){
        this.list = this.conditions.map(item=>item)
      },
      defaultValue() {
        this.setDefaultValueChecked()
      }
    },
    methods: {
      change() {
        this.list.splice()
        let choosedArr = this.list.filter(item => item.checked)
        this.$emit('change', choosedArr)
      },
      /**
       * @description 全部选择
      */
      selectAllOptions() {
        this.list.splice()
        let choosedArr = this.list.map(item => { 
          item.checked = true;
          return item;
         })
        this.isSelectAll = true;
        this.$emit('change', choosedArr)
      },
      /**
       * @description 取消选择
      */
      clearAllOptions() {
        this.list.splice();
        let choosedArr = this.list.filter(item => item.field === 'edit');
        this.list.map(item => {
          item.checked = false;
          return item;
        })
        if(choosedArr.length){
          choosedArr[0].checked = true;
          choosedArr[0].disabled = true;
        }
        this.isSelectAll = false;
        this.$emit('change', choosedArr)
      },
      setDefaultValueChecked() {
        if(typeof this.defaultValue !== 'string')  return ;
        let defaultValueList = this.defaultValue.split(SET_QUERY_SHOW_KEY_SPLIT)
        this.list.forEach(item => {
          item.checked = defaultValueList.includes(item[this.keyWord])
        })
        this.list.splice()
      },
      setLabel(condition) {
        /*if (condition.sheetName && this.isShowSuffix) {
          return `${condition.label}-${HEAD_FOOT_CODE[condition.sheetName]}`
        }*/
        return condition.label || condition.title || ''
      },
      disableItem(condition, index) {
        let label = condition.label || condition.title || '';
        if (label === '操作') {
          condition.checked = true;
          condition.disabled = true;
          return true
        } 
        
      }
    }
  }
</script>

<style scoped lang="scss">
  .addCondition {
    display: inline;
    margin-left: 8px;
  }

  .maxDropDown {
    max-height: 400px;
    overflow-y: auto;
  }

  .selectAllAndClearAll{
    display: inline-block;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    padding: 10px 58px;
    border-top: 1px solid #f3f3f3;
    color: #666666;
  }
</style>